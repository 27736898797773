import apiClient from '@/services/axios'
import store from 'store'
// import { notification } from 'ant-design-vue'

export async function login(email, password) {
  return apiClient
    .post('/api/users/login', {
      email,
      password,
      role: 'dashboard',
    })
    .then((response) => {
      console.log(response, 'response login email')
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      } else {
        return apiClient
          .post('/api/users/login', {
            email,
            password,
            role: 'verificator',
          })
          .then((res) => {
            if (res) {
              const { accessToken } = res.data
              if (accessToken) {
                store.set('accessToken', accessToken)
              }
              res.data.role = 'YKI'
              return res.data
            } 
            // else {
            //   notification.warning({
            //     message: 'Gagal',
            //     description: 'Email / Password Salah',
            //     duration: 5,
            //   })
            // }
          })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
export async function loginNew(email, password, role) {
  return apiClient
    .post('/api/users/login', {
      email,
      password,
      role,
    })
    .then((res) => {
      if (res) {
        const { accessToken } = res.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return res.data
      } 
      // else {
      //   notification.warning({
      //     message: 'Gagal',
      //     description: 'Email / Password Salah',
      //     duration: 5,
      //   })
      // }
    })
    .catch((err) => {
      console.log(err)
    })
}

export async function loginGoogle(credential) {
  // console.log('credential', credential)
  return apiClient
    .post('/api/users/login/google', {
      "id_token": credential,
    })
    .then((response) => {
      if (response) {
        // console.log(response, 'response Login Google')
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function loginverificator(email, password) {
  return apiClient
    .post('/api/users/login', {
      email,
      password,
      role: 'verificator',
    })
    .then((response) => {
      console.log(response, 'response')
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}

// eslint-disable-next-line camelcase
export async function register(email, password, name, roles, medical_facility_id, phone_no, specialization_id) {
  return apiClient
    .post('/api/users/register', {
      email,
      password,
      name,
      roles,
      medical_facility_id,
      phone_no,
      specialization_id,
    })
    .then((response) => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}

// eslint-disable-next-line camelcase
export async function registerGoogle(id, password, name, roles, medical_facility_id, phone_no, token_id, specialization_id) {
  // console.log('id', id)
  // console.log('password', password)
  // console.log('name', name)
  // console.log('roles', roles)
  // console.log('medical_facility_id', medical_facility_id)
  // console.log('phone_no', phone_no)
  // console.log('token_id', token_id)
  // console.log('specialization_id', specialization_id)
  return apiClient
    .put('/api/users/'+ id, {
      password,
      name,
      roles,
      medical_facility_id,
      phone_no,
      "id_token": token_id,
      specialization_id,
    })
    .then((response) => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}
export async function currentAccount() {
  return apiClient
    .get('/api/users/account')
    .then((response) => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function logout() {
  store.remove('accessToken')
  return true
  // return apiClient
  //   .get('/users/logout')
  //   .then(() => {
  //     store.remove('accessToken')
  //     return true
  //   })
  //   .catch(err => console.log(err))
}
