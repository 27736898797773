import axios from 'axios'
import store from 'store'
import { notification } from 'ant-design-vue'
// import { BASE_URL } from '@/config'

const CancelToken = axios.CancelToken
const source = CancelToken.source()

// export const requestInterceptor = config => {
//   config.cancelToken = source.token
//   return config
// }

const apiClient = axios.create({
  // baseURL: BASE_URL,
  // baseURL: BASE_URL,
  // baseURL: 'http://localhost:3011/',
  // baseURL: 'http://139.59.238.189/',
  // baseURL: 'https://medicalsync.digitala.id/dev/',
  baseURL: 'https://medicalsync.digitala.id/',
  // baseURL: 'https://pulih-api-prod.digitala.id:3002/',
  // baseURL: 'http://206.189.95.69:3001/',
  // baseURL: 'http://206.189.95.69:3001',
  // baseURL: BASE_URL,
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

// apiClient.interceptors.request.use(requestInterceptor)

apiClient.interceptors.request.use((request) => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, (error) => {
  // Errors handling
  // console.log('errornya apa' + JSON.stringify(error))
  // console.log('status apa' + JSON.stringify(error.response.data.status))
  // console.log('message apa' + JSON.stringify(error.response.data.message))
  // console.log('erroor' + JSON.stringify(error.response.data.errorList))
  if (error && error.response && error.response.data) {
    if (error.response.data.status){
      if (error.response.data.status !== 'USER_NOT_FOUND' &&
          error.response.data.status !== 'INVALID_PASSWORD' &&
          error.response.data.status !== 'NOT_AUTHORIZED'
      ){
        notification.warning({
          message: `Gagal: ${error.response.data.status}`,
          description: error.response.data.message,
          duration: 5,
        })
      } else if (error.response.data.status === 'USER_NOT_FOUND') {
        notification.warning({
          message: 'Gagal Login',
          description: 'Akun tidak ditemukan',
          duration: 5,
        })
      } else if (error.response.data.status === 'INVALID_PASSWORD') {
        notification.warning({
          message: 'Gagal Login',
          description: 'Password salah',
          duration: 5,
        })
      } else if (error.response.data.status === 'NOT_AUTHORIZED') {
        notification.warning({
          message: 'Account Error!',
          description: 'Silahkan Logout terlebih dahulu!',
          duration: 5,
        })
      }
    } else {
      if(error.response.data.errors){
        error.response.data.errors.forEach((element, index) => {
          if (
            element !== 'invalid email' &&
            element !== 'invalid password' &&
            element !== 'Data target tidak ditemukan' &&
            element !== 'Data code tidak ditemukan'
          ) {
            notification.warning({
              message: 'Gagal',
              description: element,
              duration: 5,
            })
          }
        })
      } else {
        notification.warning({
          message: 'Permintaan Gagal',
          description: error.response.data.message,
          duration: 5,
        })
      }
    }
    // if (error.response.data.errorList) {
    //   error.response.data.errorList.forEach((element, index) => {
    //     if (
    //       element !== 'invalid email' &&
    //       element !== 'invalid password' &&
    //       element !== 'Data target tidak ditemukan' &&
    //       element !== 'Data code tidak ditemukan'
    //     ) {
    //       notification.warning({
    //         message: 'Gagal',
    //         description: element,
    //         duration: 5,
    //       })
    //     }
    //   })
    // } else {
    //   notification.warning({
    //     message: 'Gagal',
    //     description: 'Permintaan gagal dengan alasan: ' + error,
    //     duration: 5,
    //   })
    // }
  } else {
    notification.warning({
      message: 'Gagal',
      description: 'Tidak dapat terhubung ke server dengan alasan: ' + error,
      duration: 5,
    })
  }
})

export default apiClient
export { source }
