import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
// import store from '@/store'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  // mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: () => {
        const role = window.localStorage.getItem('role');
        if (role === 'dokter') {
          return '/pemeriksaan/dokter';
        } else if (role === 'perawat') {
          return '/pemeriksaan/perawat';
        } else {
          return '/dashboard/';
        }
      },
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
        roles: [
          'admin',
          'dokter',
          'perawat',
        ],
      },
      children: [
        // Profile
        {
          path: '/profile/account',
          meta: {
            title: 'Profile',
          },
          component: () => import('./views/aplikasi/profile'),
          children: [
            {
              path: '',
              meta: {
                title: 'Profile Edit Password',
              },
              component: () =>
                import('./views/aplikasi/profile/content/editpassword'),
            },
          ],
        },
        // Dashboards
        {
          path: '/dashboard/umum',
          meta: {
            title: 'Dashboard Umum',
            roles: [
              'admin',
              'dokter',
              'perawat',
            ],
          },
          component: () => import('./views/aplikasi/dashboard/dashboard'),
        },
        // {
        //   path: '/dashboard/tagrisso',
        //   meta: {
        //     title: 'Dashboard Tagrisso',
        //     roles: ['admin', 'tagrisso', 'astrazeneca'],
        //   },
        //   component: () =>
        //     import('./views/aplikasi/dashboard/dashboardTagrisso'),
        // },
        // {
        //   path: '/dashboard/faslodex',
        //   meta: {
        //     title: 'Dashboard Faslodex',
        //     roles: ['admin', 'faslodex', 'astrazeneca'],
        //   },
        //   component: () =>
        //     import('./views/aplikasi/dashboard/dashboardFaslodex'),
        // },
        // {
        //   path: '/dashboard/lynparza',
        //   meta: {
        //     title: 'Dashboard Lynparza',
        //     roles: ['admin', 'lynparza', 'astrazeneca'],
        //   },
        //   component: () =>
        //     import('./views/aplikasi/dashboard/dashboardLynparza'),
        // },
        // {
        //   path: '/dashboard/imfinzi',
        //   meta: {
        //     title: 'Dashboard Imfinzi',
        //     roles: ['admin', 'imfinzi', 'astrazeneca'],
        //   },
        //   component: () =>
        //     import('./views/aplikasi/dashboard/dashboardImfinzi'),
        // },
        // {
        //   path: '/dashboard/calquence',
        //   meta: {
        //     title: 'Dashboard Calquence',
        //     roles: ['admin', 'calquence', 'astrazeneca'],
        //   },
        //   component: () =>
        //     import('./views/aplikasi/dashboard/dashboardCalquence'),
        // },
        // {
        //   path: '/dashboard/latest',
        //   meta: {
        //     title: 'Latest Patients',
        //     roles: ['admin', 'astrazeneca'],
        //   },
        //   component: () => import('./views/aplikasi/dashboard/dashboardLatest'),
        // },
        {
          path: '/rekam-medis/:id',
          meta: {
            title: 'Daftar Rekam Medis',
          },
          component: () => import('./views/aplikasi/rekammedis/index'),
        },
        {
          path: '/formulir/:id',
          meta: {
            title: 'Formulir',
          },
          component: () => import('./views/aplikasi/formulir/index'),
        },
        {
          path: '/pasien',
          meta: {
            title: 'Pasien',
            roles: [
              'admin',
              'dokter',
              'perawat',
            ],
          },
          component: () => import('./views/aplikasi/pasien/index'),
        },
        {
          path: '/target-pasien',
          meta: {
            title: 'Target Pasien',
          },
          component: () => import('./views/aplikasi/targetpasien/index'),
        },
        {
          path: '/target-pasien/tambah',
          meta: {
            title: 'Tambah Target Pasien',
          },
          component: () => import('./views/aplikasi/targetpasien/create'),
        },
        {
          path: '/target-pasien/details/:id',
          meta: {
            title: 'Detail Target Pasien',
          },
          component: () => import('./views/aplikasi/targetpasien/details'),
        },
        {
          path: '/pasien/tambah-old',
          meta: {
            title: 'Tambah Pasien',
            roles: [
              'admin',
              'perawat',
            ],
          },
          component: () => import('./views/aplikasi/pasien/create-new'),
        },
        {
          path: '/pasien/tambah',
          meta: {
            title: 'Tambah Pasien',
            roles: [
              'admin',
              'perawat',
            ],
          },
          component: () => import('./views/aplikasi/pasien/registration'),
        },
        {
          path: '/kunjungan/tambah/:id',
          meta: {
            title: 'Tambah Kunjungan',
            roles: [
              'admin',
              'perawat',
            ],
          },
          component: () => import('./views/aplikasi/visit/create'),
        },
        {
          path: '/pemeriksaan/perawat/',
          meta: {
            title: 'Daftar Pemeriksaan Perawat',
            roles: [
              'admin',
              'perawat',
            ],
          },
          component: () => import('./views/aplikasi/visit/dashboardPerawat'),
        },
        {
          path: '/pemeriksaan/perawat/periksa/:id',
          meta: {
            title: 'Pemeriksaan Perawat (Anamnesis)',
            roles: [
              'admin',
              'perawat',
            ],
          },
          component: () => import('./views/aplikasi/visit/nurseAssessment'),
        },
        {
          path: '/pemeriksaan/dokter/',
          meta: {
            title: 'Daftar Pemeriksaan Dokter',
            roles: [
              'admin',
              'dokter',
            ],
          },
          component: () => import('./views/aplikasi/visit/dashboardDokter'),
        },
        {
          path: '/pemeriksaan/dokter/periksa/:id',
          meta: {
            title: 'Pemeriksaan Dokter (Rawat Jalan)',
            roles: [
              'admin',
              'dokter',
            ],
          },
          component: () => import('./views/aplikasi/visit/doctorAssessment'),
        },
        {
          path: '/pemeriksaan/detail/:id',
          meta: {
            title: 'Detail Pemeriksaan',
            roles: [
              'admin',
              'dokter',
              'perawat',
            ],
          },
          component: () => import('./views/aplikasi/visit/history'),
        },
        // {
        //   path: '/pasien/tambah-old',
        //   meta: {
        //     title: 'Tambah Pasien',
        //   },
        //   component: () => import('./views/aplikasi/pasien/create'),
        // },
        {
          path: '/pasien/program/tambah',
          meta: {
            title: 'Tambah Pasien',
          },
          component: () => import('./views/aplikasi/pasien/updateProgram'),
        },
        {
          path: '/pasien/program/tambah/:id',
          meta: {
            title: 'Tambah Pasien',
          },
          component: () => import('./views/aplikasi/pasien/updateProgram'),
        },
        {
          path: '/pasien/update',
          meta: {
            title: 'Tambah Pasien',
          },
          component: () => import('./views/aplikasi/pasien/updatePasien'),
        },
        {
          path: '/pasien/details/:id',
          meta: {
            title: 'Detail Pasien',
          },
          component: () => import('./views/aplikasi/pasien/details'),
        },
        // {
        //   path: '/artikel',
        //   meta: {
        //     title: 'Artikel',
        //     roles: ['admin', 'astrazeneca'],
        //   },
        //   component: () => import('./views/aplikasi/artikel/index'),
        // },
        // {
        //   path: '/artikel/tambah',
        //   meta: {
        //     title: 'Tambah Artikel',
        //   },
        //   component: () => import('./views/aplikasi/artikel/create'),
        // },
        // {
        //   path: '/artikel/details/:id',
        //   meta: {
        //     title: 'Detail Artikel',
        //   },
        //   component: () => import('./views/aplikasi/artikel/details'),
        // },
        {
          path: '/dokter',
          meta: {
            title: 'Dokter',
            roles: ['admin', 'astrazeneca'],
          },
          component: () => import('./views/aplikasi/dokter/index'),
        },
        {
          path: '/dokter/tambah',
          meta: {
            title: 'Tambah Dokter',
          },
          component: () => import('./views/aplikasi/dokter/create'),
        },
        {
          path: '/dokter/details/:id',
          meta: {
            title: 'Detail Dokter',
          },
          component: () => import('./views/aplikasi/dokter/details'),
        },
        {
          path: '/farmasi',
          meta: {
            title: 'Farmasi',
            roles: ['admin', 'astrazeneca'],
          },
          component: () => import('./views/aplikasi/farmasi/index'),
        },
        {
          path: '/farmasi/tambah',
          meta: {
            title: 'Tambah Farmasi',
          },
          component: () => import('./views/aplikasi/farmasi/create'),
        },
        {
          path: '/farmasi/details/:id',
          meta: {
            title: 'Detail Farmasi',
          },
          component: () => import('./views/aplikasi/farmasi/details'),
        },
        {
          path: '/farmasi/details/:id/change-password',
          meta: {
            title: 'Detail Farmasi',
          },
          component: () => import('./views/aplikasi/farmasi/changePassword'),
        },
        {
          path: '/laboratorium',
          meta: {
            title: 'Laboratorium',
            roles: ['admin', 'astrazeneca'],
          },
          component: () => import('./views/aplikasi/laboratorium/index'),
        },
        {
          path: '/laboratorium/tambah',
          meta: {
            title: 'Tambah Laboratorium',
          },
          component: () => import('./views/aplikasi/laboratorium/create'),
        },
        {
          path: '/laboratorium/details/:id',
          meta: {
            title: 'Detail Laboratorium',
          },
          component: () => import('./views/aplikasi/laboratorium/details'),
        },
        {
          path: '/obat',
          meta: {
            title: 'Obat',
            roles: ['admin', 'astrazeneca'],
          },
          component: () => import('./views/aplikasi/obat/index'),
        },
        {
          path: '/obat/tambah',
          meta: {
            title: 'Tambah Obat',
          },
          component: () => import('./views/aplikasi/obat/create'),
        },
        {
          path: '/obat/details/:id',
          meta: {
            title: 'Detail Obat',
          },
          component: () => import('./views/aplikasi/obat/details'),
        },
        {
          path: '/verifikator',
          meta: {
            title: 'Verifikator',
            // roles: ['admin', 'verifikator', 'astrazeneca'],
            roles: ['admin', 'dokter', 'perawat'],
          },
          component: () => import('./views/aplikasi/verifikator/index'),
        },
        {
          path: '/verifikator/tambah',
          meta: {
            title: 'Tambah Verifikator',
          },
          component: () => import('./views/aplikasi/verifikator/create'),
        },
        {
          path: '/verifikator/details/:id',
          meta: {
            title: 'Detail Verifikator',
          },
          component: () => import('./views/aplikasi/verifikator/details'),
        },
        {
          path: '/verifikator/details/:id/change-password',
          meta: {
            title: 'Detail Verifikator',
          },
          component: () =>
            import('./views/aplikasi/verifikator/changePassword.vue'),
        },
        {
          path: '/voucher',
          meta: {
            title: 'Voucher',
            roles: ['admin', 'astrazeneca'],
          },
          component: () => import('./views/aplikasi/voucher/index'),
        },
        {
          path: '/voucher/tambah',
          meta: {
            title: 'Tambah Voucher',
          },
          component: () => import('./views/aplikasi/voucher/create'),
        },
        {
          path: '/voucher/details/:id',
          meta: {
            title: 'Detail Voucher',
          },
          component: () => import('./views/aplikasi/voucher/details'),
        },
        // Survey Question
        {
          path: '/survey',
          meta: {
            title: 'Survey',
            roles: ['admin', 'dokter'],
          },
          component: () => import('./views/aplikasi/survey'),
        },
        {
          path: '/survey/pertanyaan/tambah',
          meta: {
            title: 'Tambah Pertanyaan Survey',
          },
          component: () => import('./views/aplikasi/survey/createQuestion.vue'),
        },
        {
          path: '/survey/jawaban/tambah',
          meta: {
            title: 'Tambah Jawaban Survey',
          },
          component: () => import('./views/aplikasi/survey/createAnswer.vue'),
        },

        // Not Use
        {
          path: '/dashboard/beta',
          meta: {
            title: 'Dashboard Beta',
          },
          component: () => import('./views/dashboard/beta'),
        },
        {
          path: '/dashboard/crypto',
          meta: {
            title: 'Dashboard Crypto',
          },
          component: () => import('./views/dashboard/crypto'),
        },
        {
          path: '/dashboard/gamma',
          meta: {
            title: 'Dashboard Gamma',
          },
          component: () => import('./views/dashboard/gamma'),
        },
        // Ecommerce
        {
          path: '/ecommerce/dashboard',
          meta: {
            title: 'Ecommerce Dashboard',
          },
          component: () => import('./views/ecommerce/dashboard'),
        },
        {
          path: '/ecommerce/orders',
          meta: {
            title: 'Ecommerce Orders',
          },
          component: () => import('./views/ecommerce/orders'),
        },
        {
          path: '/ecommerce/product-catalog',
          meta: {
            title: 'Ecommerce Product Catalog',
          },
          component: () => import('./views/ecommerce/product-catalog'),
        },
        {
          path: '/ecommerce/product-details',
          meta: {
            title: 'Ecommerce Product Details',
          },
          component: () => import('./views/ecommerce/product-details'),
        },
        {
          path: '/ecommerce/cart',
          meta: {
            title: 'Ecommerce Cart',
          },
          component: () => import('./views/ecommerce/cart'),
        },
        // Widgets
        {
          path: '/widgets/general',
          meta: {
            title: 'Widgets / General',
          },
          component: () => import('./views/widgets/general'),
        },
        {
          path: '/widgets/lists',
          meta: {
            title: 'Widgets / Lists',
          },
          component: () => import('./views/widgets/lists'),
        },
        {
          path: '/widgets/tables',
          meta: {
            title: 'Widgets / Tables',
          },
          component: () => import('./views/widgets/tables'),
        },
        {
          path: '/widgets/charts',
          meta: {
            title: 'Widgets / Charts',
          },
          component: () => import('./views/widgets/charts'),
        },
        // Apps
        {
          path: '/apps/calendar',
          meta: {
            title: 'Apps / Calendar',
          },
          component: () => import('./views/apps/calendar'),
        },
        {
          path: '/apps/digitalocean-create',
          meta: {
            title: 'Apps / DigitalOcean Create',
          },
          component: () => import('./views/apps/digitalocean-create'),
        },
        {
          path: '/apps/digitalocean-droplets',
          meta: {
            title: 'Apps / DigitalOcean Droplets',
          },
          component: () => import('./views/apps/digitalocean-droplets'),
        },
        {
          path: '/apps/gallery',
          meta: {
            title: 'Apps / Gallery',
          },
          component: () => import('./views/apps/gallery'),
        },
        {
          path: '/apps/github-discuss',
          meta: {
            title: 'Apps / GitHub Discuss',
          },
          component: () => import('./views/apps/github-discuss'),
        },
        {
          path: '/apps/github-explore',
          meta: {
            title: 'Apps / GitHub Explore',
          },
          component: () => import('./views/apps/github-explore'),
        },
        {
          path: '/apps/google-analytics',
          meta: {
            title: 'Apps / Google Analytics',
          },
          component: () => import('./views/apps/google-analytics'),
        },
        {
          path: '/apps/helpdesk-dashboard',
          meta: {
            title: 'Apps / Helpdesk Dashboard',
          },
          component: () => import('./views/apps/helpdesk-dashboard'),
        },
        {
          path: '/apps/jira-agile-board',
          meta: {
            title: 'Apps / Jira Agile Board',
          },
          component: () => import('./views/apps/jira-agile-board'),
        },
        {
          path: '/apps/jira-dashboard',
          meta: {
            title: 'Apps / Jira Dashboard',
          },
          component: () => import('./views/apps/jira-dashboard'),
        },
        {
          path: '/apps/mail',
          meta: {
            title: 'Apps / Mail',
          },
          component: () => import('./views/apps/mail'),
        },
        {
          path: '/apps/messaging',
          meta: {
            title: 'Apps / Mesagging',
          },
          component: () => import('./views/apps/messaging'),
        },
        {
          path: '/apps/profile',
          meta: {
            title: 'Apps / Profile',
          },
          component: () => import('./views/apps/profile'),
        },
        {
          path: '/apps/todoist-list',
          meta: {
            title: 'Apps / Todoist List',
          },
          component: () => import('./views/apps/todoist-list'),
        },
        {
          path: '/apps/wordpress-add',
          meta: {
            title: 'Apps / Wordpress Add',
          },
          component: () => import('./views/apps/wordpress-add'),
        },
        {
          path: '/apps/wordpress-post',
          meta: {
            title: 'Apps / Wordpress Post',
          },
          component: () => import('./views/apps/wordpress-post'),
        },
        {
          path: '/apps/wordpress-posts',
          meta: {
            title: 'Apps / Wordpress Posts',
          },
          component: () => import('./views/apps/wordpress-posts'),
        },
        // Cards
        {
          path: '/cards/basic-cards',
          meta: {
            title: 'Cards / Basic',
          },
          component: () => import('./views/cards/basic-cards'),
        },
        {
          path: '/cards/tabbed-cards',
          meta: {
            title: 'Cards / Tabbed',
          },
          component: () => import('./views/cards/tabbed-cards'),
        },
        // UI Kits
        {
          path: '/ui-kits/bootstrap',
          meta: {
            title: 'UI Kit / Bootstrap',
          },
          component: () => import('./views/ui-kits/bootstrap'),
        },
        {
          path: '/ui-kits/antd',
          meta: {
            title: 'UI Kit / Ant Design',
          },
          component: () => import('./views/ui-kits/antd'),
        },
        // Tables
        {
          path: '/tables/bootstrap',
          meta: {
            title: 'Tables / Bootstrap',
          },
          component: () => import('./views/tables/bootstrap'),
        },
        {
          path: '/tables/antd',
          meta: {
            title: 'Tables / Ant Design',
          },
          component: () => import('./views/tables/antd'),
        },
        // Charts
        {
          path: '/charts/chartistjs',
          meta: {
            title: 'Charts / Chartist.js',
          },
          component: () => import('./views/charts/chartistjs'),
        },
        {
          path: '/charts/chartjs',
          meta: {
            title: 'Charts / Chart.js',
          },
          component: () => import('./views/charts/chartjs'),
        },
        {
          path: '/charts/c3',
          meta: {
            title: 'Charts / C3 Charts',
          },
          component: () => import('./views/charts/c3'),
        },
        // Icons
        {
          path: '/icons/feather-icons',
          meta: {
            title: 'Icons / Feather',
          },
          component: () => import('./views/icons/feather-icons'),
        },
        {
          path: '/icons/fontawesome',
          meta: {
            title: 'Icons / Fontawesome',
          },
          component: () => import('./views/icons/fontawesome'),
        },
        {
          path: '/icons/linearicons-free',
          meta: {
            title: 'Icons / Linearicons Free',
          },
          component: () => import('./views/icons/linearicons-free'),
        },
        {
          path: '/icons/icomoon-free',
          meta: {
            title: 'Icons / Icomoon Free',
          },
          component: () => import('./views/icons/icomoon-free'),
        },
        // Advanced
        {
          path: '/advanced/form-examples',
          meta: {
            title: 'Advanced / Form Examples',
          },
          component: () => import('./views/advanced/form-examples'),
        },
        {
          path: '/advanced/email-templates',
          meta: {
            title: 'Advanced / Email Templates',
          },
          component: () => import('./views/advanced/email-templates'),
        },
        {
          path: '/advanced/utilities',
          meta: {
            title: 'Advanced / Utilities',
          },
          component: () => import('./views/advanced/utilities'),
        },
        {
          path: '/advanced/grid',
          meta: {
            title: 'Advanced / Grid',
          },
          component: () => import('./views/advanced/grid'),
        },
        {
          path: '/advanced/typography',
          meta: {
            title: 'Advanced / Typography',
          },
          component: () => import('./views/advanced/typography'),
        },
        {
          path: '/advanced/pricing-tables',
          meta: {
            title: 'Advanced / Pricing Tables',
          },
          component: () => import('./views/advanced/pricing-tables'),
        },
        {
          path: '/advanced/invoice',
          meta: {
            title: 'Advanced / Invoice',
          },
          component: () => import('./views/advanced/invoice'),
        },
        {
          path: '/advanced/colors',
          meta: {
            title: 'Advanced / Colors',
          },
          component: () => import('./views/advanced/colors'),
        },
      ],
    },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/auth/404',
          meta: {
            title: 'Error 404',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          meta: {
            title: 'Sign In',
          },
          component: () => import('./views/auth/login'),
        },
        {
          path: '/auth/register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/auth/registerFromGoogleAccount/',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/registerFromGoogleAccount'),
        },
        {
          path: '/auth/forgot-password',
          meta: {
            title: 'Forgot Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        {
          path: '/auth/confirm-email/:id',
          meta: {
            title: 'Confirm Email',
          },
          component: () => import('./views/auth/confirm-email'),
        },
        {
          path: '/auth/lockscreen',
          meta: {
            title: 'Lockscreen',
          },
          component: () => import('./views/auth/lockscreen'),
        },
      ],
    },
    {
      path: '/privacy-policy',
      component: () => import('./views/privacy-policy'),
      meta: {
        title: 'privpol',
        authRequired: false,
      },
    },
    {
      path: '/dashboard',
      redirect: 'dashboard/umum',
    },

    // Redirect to 404
    {
      path: '*',
      redirect: 'auth/404',
      hidden: true,
    },
  ],
})

router.beforeEach((to, from, next) => {
  const role = window.localStorage.getItem('role')

  if (to.matched.some((record) => record.meta.authRequired)) {
    const authorized = window.localStorage.getItem('authorized')
    if (!authorized) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    } else {
      if (to.meta.roles) {
        if (to.meta.roles.includes(role)) {
          next()
        } else {
          next({
            path: '/auth/404',
          })
        }
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
